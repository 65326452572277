<template>
  <div class="footer-bottom">
    <span
      >榆林华云信息服务有限责任公司版权所有Copyright@2010-2030 AIIRight
      Reserved</span
    >
    <p>公司地址：陕西省榆林市榆神工业区大保当镇清水工业园</p>
    <p>陕ICP备2024038963号</p>
    <p>
      <a href="https://beian.miit.gov.cn/"></a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
body {
  margin: 0;
}

.footer-bottom {
  background: #2c3038;
  width: 100%;
  height: 10%;
  text-align: center;
  color: #444040;
  font-size: 14px;
}

.footer-bottom span {
  font-size: 15px;
  line-height: 50px;
}
.footer-bottom p:nth-of-type(1) {
  font-size: 15px;
}
.footer-bottom p:nth-of-type(2) {
  font-size: 15px;
  margin-top: 10px;
}
</style>
