<template>
  <div class="container">
    <router-view />
    <Foot />
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue';

export default {
  components: {
    Foot,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  height: 100vh;
}
</style>
