import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    component: HomeView
  },
  {
    path: '/Programme',
    name: 'Programme',
    component: () => import('../views/Programme.vue')
  }
]

//hash
const router = createRouter({
    history: createWebHashHistory(),
    routes
  })
  

  // //history
  // const hash = createWebHistory();
  // const router = createRouter({
  //   history: hash,
  //   // base: "/cd/",
  //   routes: []
  // })

  export default router
